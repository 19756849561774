export enum Module {
  REALESTATE = 'REALESTATE',
  PROJECT = 'PROJECT',
  CRM = 'CRM',
  CALLCENTER = 'CALLCENTER',
  B2B_PUBLIC_REALESTATE = 'B2B_PUBLIC_REALESTATE',
  FORECLOSURE_REQUEST = 'FORECLOSURE_REQUEST',
  UPGRADE_ACCOUNT = 'UPGRADE_ACCOUNT',
  LOAN = 'LOAN',
  NOTARIZATION = 'NOTARIZATION',
  REGISTER = 'REGISTER',
}
export interface B2bSignInVariables {
  accessToken: string
  refreshToken?: string
}

export interface B2bSignInData {
  data: {
    data?: {
      b2bSignIn?: MutationOfAccessTokenType
    }
  }
}

export interface MutationOfAccessTokenType {
  payload?: AccessTokenType
  msg?: String
  success?: Boolean
}

export interface AccessTokenType {
  accessToken?: String
  refreshToken?: String
  memberId?: String
  fullName?: String
  system?: String[]
  developer?: String
  id?: ID
}

export type Boolean = boolean
export type String = string
export type ID = string
export type Float = number
export type DateTime = string
export type ObjectID = string
export type JSONObject = object

export interface B2bGetMeData {
  data: {
    b2bGetMe?: MutationOfB2BUserType
  }
}

export interface MutationOfB2BUserType {
  payload?: B2BUserType
  msg?: String
  success?: Boolean
}

export interface B2BUserType {
  id?: String
  userType?: String
  fullName?: String
  employeeCode?: String
  email?: String
  avatar?: AnotherServiceFileType
  gender?: String
  phone?: String
  username?: String
  secondPhone?: String
  province?: AddressType
  district?: AddressType
  ward?: AddressType
  street?: AddressType
  houseNumber?: String
  company?: String
  idNumber?: String
  dateOfBirth?: DateTime
  birthDate?: DateTime
  creator?: B2BUserType
  editor?: B2BUserType
  idImages?: AnotherServiceFileType[]
  approveStatus?: ApproveStatusOutputType
  approvedBy?: B2BUserType
  createdAt?: DateTime
  updatedAt?: DateTime
  approvedAt?: DateTime
  role?: String
  position?: String
  isLocked?: Boolean
  deviceToken?: String
  attachments?: AnotherServiceFileType[]
  departments?: EmployeeDepartmentType[]
  employeeDepartments?: String[]
  projectEmployees?: String[]
  productEmployees?: String[]
  productPermissions?: PermissionOutputType[]
  projectPermissions?: PermissionOutputType[]
  b2cPermissions?: PermissionOutputType[]
  system?: String[]
  deletedAt?: DateTime
  amount?: Float
  lastOnline?: DateTime
  lockByRtAt?: DateTime
  lockedByBo?: String
  configs?: UserConfigType
  isPersonal?: Boolean
  permissionGroup?: String
  permissions?: PermissionOutputType[]
  modules?: Module[]
}
export type AnotherServiceFileType = YoutubeFile | FileWithFullUrls

export interface YoutubeFile {
  fileId?: ID
  type?: String
  youtubeMetadata?: YoutubeMetadata
}

export interface YoutubeMetadata {
  description?: String
  thumbnailUrl?: String
  title?: String
  videoId?: String
}

export interface FileWithFullUrls {
  createdAt?: DateTime
  creator?: String
  fileId?: ID
  originalUrl?: String
  type?: String
  updatedAt?: DateTime
  urls?: Url[]
}

export interface Url {
  type?: String
  url?: String
}

export interface AddressType {
  id?: ObjectID
  name?: String
}

export interface ApproveStatusOutputType {
  reason?: String
  reasonType?: String
  status?: String
}

export interface EmployeeDepartmentType {
  createdAt?: DateTime
  creator?: B2BUserType
  deletedAt?: DateTime
  deletedBy?: B2BUserType
  department?: DepartmentOutputType
  editor?: B2BUserType
  id?: String
  manager?: EmployeeDepartmentType
  positionName?: String
  positionType?: String
  secretaries?: EmployeeDepartmentType[]
  updatedAt?: DateTime
  user?: B2BUserType
}

export interface DepartmentOutputType {
  code?: String
  company?: String
  createdAt?: DateTime
  creator?: B2BUserType
  deletedAt?: DateTime
  editor?: B2BUserType
  id?: String
  leader?: EmployeeDepartmentType
  name?: String
  note?: String
  parentDepartment?: DepartmentOutputType
  province?: AddressType
  region?: AddressType
  updatedAt?: DateTime
}

export interface PermissionOutputType {
  configs?: JSONObject
  id?: String
  name?: String
}

export interface UserConfigType {
  defaultWallet?: String
}

// export interface UserAddressModel {
//   addressLine: string
//   city: string
//   state: string
//   postCode: string
// }

// export interface UserCommunicationModel {
//   email: boolean
//   sms: boolean
//   phone: boolean
// }

// export interface UserEmailSettingsModel {
//   emailNotification?: boolean
//   sendCopyToPersonalEmail?: boolean
//   activityRelatesEmail?: {
//     youHaveNewNotifications?: boolean
//     youAreSentADirectMessage?: boolean
//     someoneAddsYouAsAsAConnection?: boolean
//     uponNewOrder?: boolean
//     newMembershipApproval?: boolean
//     memberRegistration?: boolean
//   }
//   updatesFromKeenthemes?: {
//     newsAboutKeenthemesProductsAndFeatureUpdates?: boolean
//     tipsOnGettingMoreOutOfKeen?: boolean
//     thingsYouMissedSindeYouLastLoggedIntoKeen?: boolean
//     newsAboutStartOnPartnerProductsAndOtherServices?: boolean
//     tipsOnStartBusinessProducts?: boolean
//   }
// }

// export interface UserSocialNetworksModel {
//   linkedIn: string
//   facebook: string
//   twitter: string
//   instagram: string
// }

// export interface UserModel {
//   id: number
//   username: string
//   password: string | undefined
//   email: string
//   first_name: string
//   last_name: string
//   fullname?: string
//   occupation?: string
//   companyName?: string
//   phone?: string
//   roles?: Array<number>
//   pic?: string
//   language?: 'en' | 'de' | 'es' | 'fr' | 'ja' | 'zh' | 'ru'
//   timeZone?: string
//   website?: 'https://keenthemes.com'
//   emailSettings?: UserEmailSettingsModel
//   auth?: AccessTokenType
//   communication?: UserCommunicationModel
//   address?: UserAddressModel
//   socialNetworks?: UserSocialNetworksModel
// }
