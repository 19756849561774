import {Module} from '../../../../../app/modules/auth'

export interface RouterProps {
  id: number
  path?: string
  title?: string
  icon?: string
  module?: Module
}

export const routersApp: RouterProps[] = [
  {
    id: 0,
    path: process.env.REACT_APP_CRM_URL,
    title: 'Quản lý CRM',
    icon: '/media/biha/icons/modules/crm-management.svg',
    module: Module.CRM,
  },
  {
    id: 1,
    path: process.env.REACT_APP_PROJECT_MANAGEMENT_URL,
    title: 'Quản lý dự án',
    icon: '/media/biha/icons/modules/project-management.svg',
    module: Module.PROJECT,
  },
  {
    id: 2,
    path: process.env.REACT_APP_PRODUCT_MANAGEMENT_URL,
    title: 'Quản lý nhà phố',
    icon: '/media/biha/icons/modules/product-management.svg',
    module: Module.REALESTATE,
  },
  {
    id: 3,
    path: process.env.REACT_APP_ORGANIZATION_MANAGEMENT_URL,
    title: 'Quản lý bộ máy tổ chức',
    icon: '/media/biha/icons/modules/organization-management.svg',
  },
  {
    id: 4,
    path: process.env.REACT_APP_EMPLOYEE_MANAGEMENT_URL,
    title: 'Quản lý nhân viên',
    icon: '/media/biha/icons/modules/employee-management.svg',
  },
  {
    id: 5,
    path: process.env.REACT_APP_CALLCENTER_MANAGEMENT_URL,
    title: 'Tổng đài thông minh',
    icon: '/media/biha/icons/modules/call-center-management.svg',
    module: Module.CALLCENTER,
  },

  {
    id: 6,
    path: process.env.REACT_APP_UPGARADE_ACCOUNT_MANAGEMENT_URL,
    title: 'Quản lý nâng cấp tài khoản ATM Nhà',
    icon: '/media/biha/icons/modules/upgrade-account-management.svg',
    module: Module.UPGRADE_ACCOUNT,
  },
  {
    id: 7,
    path: process.env.REACT_APP_BANK_MANAGEMENT_URL,
    title: 'Quản lý vay vốn ngân hàng',
    icon: '/media/biha/icons/modules/bank-loan-management.svg',
    module: Module.LOAN,
  },
  {
    id: 8,
    path: process.env.REACT_APP_NOTARIZATION_MANAGEMENT_URL,
    title: 'Quản lý hồ sơ công chứng',
    icon: '/media/biha/icons/modules/notarization-request-management.svg',
    module: Module.NOTARIZATION,
  },
  {
    id: 9,
    path: '/fluctuation-requests/list',
    title: 'Quản lý đăng ký biến động',
    icon: '/media/biha/icons/modules/register-request-management.svg',
    module: Module.REGISTER,
  },
  {
    id: 10,
    path: process.env.REACT_APP_ATMNHA_PUBLISHED_POST_MANAGEMENT_URL,
    title: 'Quản lý tin đăng lên ATM Nhà',
    icon: '/media/biha/icons/modules/post-to-atmnha-management.svg',
    module: Module.B2B_PUBLIC_REALESTATE,
  },
  {
    id: 11,
    path: process.env.REACT_APP_FORECLOSURE_MANAGEMENT_URL,
    title: 'Quản lý yêu cầu mua tài sản phát mãi',
    icon: '/media/biha/icons/modules/foreclosure-request-management.svg',
    module: Module.FORECLOSURE_REQUEST,
  },
]
